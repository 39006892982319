import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Service from '../../assets/images/services/3.jpg'

const MobileMarketing = () => {

  const [ show, setShow ] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Mobile Marketing' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">

            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Mobile Marketing</span>

              <p className='mb-4'> Today’s consumers are mobile. They connect, engage and entertain themselves through their mobile devices. Let’s help you reach your consumers through mobile marketing using various mobile platforms like:</p>

              <ul class="blog-meta list-unstyled mb-3 text-dark">
                <li className='mb-2'>WhatsApp</li>
                <li className='mb-2'>Targeted SMS</li>
                <li className='mb-2'> Live Chat </li>
                <li className='mb-2'> Mobile Apps </li>
                <li className='mb-2'> Mobile Only Websites </li>
                <li className='mb-2'> In–App Display Adverts </li>
                <li className='mb-2'>Google Mobile Platform</li>
                <li className='mb-2'>Location Geo targeting</li>
              </ul>

              <p className='mb-4'>After call Summary Adverts
                Let’s develop and implement a Mobile Marketing Campaign for your brand.</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Get Started</Link>

            </div>
          </div>

        </div>

      </section>

      <Footer />
      
      <Modal show={show} setShow={setShow} title='Mobile Marketing' />

    </div>
  )
}

export default MobileMarketing
import React, { useState } from 'react'
import LOGO from '../assets/images/logo_1.png'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import InertiaMediaBrochure from '../assets/InertiaMediaBrochure.pdf'

function Header() {

    const [open, setOpen] = useState(false)

    const [drop, setDrop] = useState(false)

    const [fix, setFixed] = useState(false)

    const fixed = () => {
        if (window.scrollY >= 90) {
            setFixed(true)
        } else {
            setFixed(false)
        }
    }

    window.addEventListener('scroll', fixed)

    return (

        <>

            <header class="header axil-header header-style-1">

                <div id="axil-sticky-placeholder"></div>

                <div class={`axil-mainmenu ${fix ? 'axil-sticky' : ''}`}>

                    <div class="container">

                        <div class="header-navbar">

                            <div class="header-logo">
                                <Link to="/"><img class="light-version-logo" src={LOGO} alt="logo" /></Link>
                                <Link to="/"><img class="sticky-logo" src={LOGO} alt="logo" /></Link>
                            </div>

                            <div class="header-main-nav">

                                <nav class={`mainmenu-nav ${open && 'show'}`}>

                                    <div class="d-block d-lg-none">
                                        <div class="mobile-nav-header mb-5">
                                            <div class="mobile-nav-logo">
                                                <Link to="/">
                                                    <img class="light-mode" width={100} src={LOGO} alt="Site Logo" />
                                                </Link>
                                            </div>
                                            <button class="mobile-menu-close" onClick={() => setOpen(false)}><i class="fas fa-times"> <FaTimes /> </i></button>
                                        </div>
                                    </div>

                                    <ul class="mainmenu">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        {/* <li><Link to="/portfolio">Our Portfolio</Link></li> */}

                                        <li onClick={() => setDrop(!drop)} class={`menu-item-has-children ${drop && 'menu-item-open open'} `}>
                                            <a href="javascript:void(0);">Services</a>
                                            <ul class="axil-submenu">
                                                <li><Link to='/social-media-marketing'>Social Media Marketing</Link></li>
                                                <li><Link to='/mobile-marketing'>Mobile Marketing</Link></li>
                                                <li><Link to='/website-development'> Website Development </Link></li>
                                                <li><Link to='/search-engine-optimisation'> Search Engine Optimisation </Link></li>
                                                <li><Link to='/growth-marketing-services'> Growth Marketing Services </Link></li>
                                                <li><Link to='/consultancy'> Consultancy & Training </Link></li>
                                                <li><Link to='/google-online-display-ad'> 'Google Online Dispaly AD Campaigns</Link></li>
                                                <li><Link to='/mobile-app-development'> Mobile & Web App Development </Link></li>
                                                <li><Link to='/brand-creative'> Brand Creative & Video production </Link></li>
                                            </ul>
                                        </li>

                                        <li><Link to="/blog">Blog</Link></li>

                                        <li><Link to="/contact">Contact</Link></li>

                                        <li className='btn_download'>
                                            <a href={InertiaMediaBrochure} download="Inertia Media Brochure" class="">Download our Brochure</a>
                                        </li>

                                    </ul>

                                </nav>

                                {open && (
                                    <div onClick={() => setOpen(false)} class="offcanvas-backdrop fade show"></div>
                                )}

                            </div>

                            <div class="header-action">
                                <ul class="list-unstyled">

                                    <li class="header-btn">
                                        <a href={InertiaMediaBrochure} download="Inertia Media Brochure" class="axil-btn btn-fill-primary text-light">Download our Brochure</a>
                                    </li>

                                    <li class="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                                        <button class="btn-wrap btn-dark" onClick={() => setOpen(!open)}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </header>

        </>

    )
}

export default Header
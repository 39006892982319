import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import BlogSliderBar from '../components/blog/BlogSliderBar'
import '../assets/css/blog.css'
import BlogCard from '../components/blog/BlogCard'
import Pagination from 'react-js-pagination';

const Blog = ({ posts, fetchPost, Loading }) => {

    return (
        <>

            <Header />

            <div className="blog-page style-5">

                <section class="all-news section-padding blog bg-transparent style-3">
                    <div class="container">

                        <div class="row gx-4 gx-lg-5">

                            <div class="col-lg-4">
                                <BlogSliderBar posts={posts} Loading={Loading} />
                            </div>

                            <div class="col-lg-8">

                                {Loading ? (

                                    <h4 className="text-center m-auto mt-5">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" style={{ width: '5rem', height: '5rem' }} role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </h4>

                                ) : (

                                    posts?.posts?.data?.length > 0 ? (

                                        posts?.posts?.data?.map((post) => (

                                            <BlogCard post={post} />

                                        ))

                                    ) : (
                                        <h4 className="text-center m-auto">
                                            No Post Found
                                        </h4>
                                    )

                                )}

                                <div className="m-auto text-center mt-5" >
                                    <Pagination
                                        activePage={posts?.posts?.current_page}
                                        itemsCountPerPage={posts?.posts?.per_page}
                                        totalItemsCount={posts?.posts?.total}
                                        onChange={(pageNumber) => fetchPost(pageNumber)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText=""
                                        lastPageText=""
                                        nextPageText=""
                                        prevPageText=""
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                </section >

            </div >

            <Footer />

        </>
    )
}

export default Blog
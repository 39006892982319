import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Service from '../../assets/images/services/6.jpg'

const SearchEngine = () => {

  const [ show, setShow ] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Search Engine Optimisation' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">
            
            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Search Engine Optimisation</span>

              <p className='mb-4'> There are 3.5 billion searches on Google daily. Clearly, consumers are turning more to search engines help them take buying decisions.</p>

              <p className='mb-4'>Let’s help you manage your search engine optimisation projects so that your brand can feature in your prospect’s search.</p>

              <p className='mb-4'>We use relevant keyword research tools, optimized content and analytics to make your brand visible on the web.</p>

              <p className="mb-4">Let’s help you get found online.</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Book A Free SEO Consultation</Link>

            </div>
          </div>

        </div>

      </section>

      <Footer />

      <Modal show={show} setShow={setShow} title='Search Engine Optimisation'/>

    </div>
  )
}

export default SearchEngine
import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Service from '../../assets/images/services/8.jpg'
import Service2 from '../../assets/images/services/9.jpg'
import Service3 from '../../assets/images/services/10.jpg'

const BrandCreative = () => {

  const [ show, setShow ] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Brand Creatives & Video Production' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">

            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">BRAND CREATIVES & VIDEO PRODUCTION</span>

              <p className='fw-bold text-dark mb-2'>  <i>We Design Creatives That Engage & Convert</i></p>
              <p className='fw-bold text-dark mb-2'>  <i>Great Brand Creatives & Videos Tell Fantastic Stories That Sell!</i></p>

              <p className='mb-4 fs-sm'> <span className="fw-bold my-3">Good Creatives Trigger Curiosity & Engagement.</span> <br />
                What are creatives? They are visual representations of your brand that help, that help to tell brand story and message to your audience. From your logo to your graphics flyers or e- flyers, book covers, brochures, banners, 2D animation, e.t.c., every design goes a long way to effectively captivate your audience, tell them your story, and get them to engage with you. At Inertia Media, we design brand creatives that stand out exceptionally.</p>

            </div>
          </div>

          <div class="process-work content-reverse" data-sal="slide-left" data-sal-duration="1000" data-sal-delay="100">
            <div class="thumbnail paralax-image">
               <img src={Service2} alt="Thumbnail" />
            </div>

            <div class="content">

              <p className='mb-4'>All products need to be developed into brands that gives them a distinct identity in the minds of the customers. At Inertia Media, we help turn products, companies and people into distinguishable brands with the following services that we offer:</p>

              <ul class="mb-3 text-dark">
                <li className='mb-2'>Brand Strategy Development </li>
                <li className='mb-2'> Brand Positioning and Key Messaging </li>
                <li className='mb-2'> Brand Identity Development Guidelines and Visuals(Logo etc) </li>
                <li className='mb-2'> Employer Branding </li>
                <li className='mb-2'>Marketing Plan Development </li>
                <li className='mb-2'> Re branding </li>
                <li className="mb-2">Marketing Strategy</li>
              </ul>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Book A Free Brand Consultation</Link>

            </div>

          </div>

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">
            <div class="thumbnail paralax-image">
               <img src={Service3} alt="Thumbnail" />
            </div>
            <div class="content">
              <span class="subtitle">Great Videos Grab Attention!</span>

              <p className='mb-3 fs-sm'>Videos currently account for over 80% of content online and on social media. However, there are boring videos and there are great videos. Great videos captivate the attention of  online audiences within the first three (3) seconds and keep their attention glued for the entirety of the video. Great video tell fantastic brand stories that grabs attention, which triggers engagement, and then conversations. At Inertia Media, we curate, shoot, edit and produce fantastic brand videos that engage and sell!</p>

              <p className='mb-3 fs-sm'>Let's Produce Your Awesome Video Now!</p>

              <p className='mb-3 fs-sm'>Enough Talk, Let's Curate Great Creatives For You!</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Talk To Us Now</Link>

            </div>
          </div>

        </div>

      </section>

      <Footer />

      <Modal show={show} setShow={setShow} title='Brand Creatives & Video Production' />

    </div>
  )
}

export default BrandCreative
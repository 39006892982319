import React from 'react'
import { Link } from 'react-router-dom'

const Call2Action = () => {
    return (

        <section class="section call-to-action-area">

            <div class="container">
                <div class="call-to-action">
                    <div class="section-heading heading-light">
                        <span class="subtitle">Let's Work Together</span>
                        <h2 class="title">Need a successful project?</h2>
                        <Link to="/contact" class="axil-btn btn-large btn-fill-white">Contact Us</Link>
                    </div>
                </div>
            </div>

        </section>

    )
}

export default Call2Action
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';
import axios from '../../app/api/axios';
import TextTruncate from 'react-text-truncate';
import LazyImage from '../LazyImage';

const BlogSliderBar = ({ posts, Loading }) => {
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState('');

  const getCategories = async () => {
    const response = await axios.get('/categories');
    setCategories(response?.data);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category')?.toLowerCase();

  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    const lowercaseCategory = category.toLowerCase();

    if (!query) {
      navigate(`/blog?category=${lowercaseCategory}`);
    } else {
      navigate(`/blog?category=${lowercaseCategory}&search=${query}`);
    }
  };

  const performSearch = () => {
    const lowercaseCategory = category ? category.toLowerCase() : '';
    const lowercaseQuery = query.toLowerCase();

    if (!lowercaseCategory) {
      navigate(`/blog?search=${lowercaseQuery}`);
    } else {
      navigate(`/blog?category=${lowercaseCategory}&search=${lowercaseQuery}`);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
  };

  return (
    <div className="side-blog style-5 pe-lg-5 mt-5 mt-lg-0 mb-50">
      <form className="search-form mb-50" onSubmit={(e) => { e.preventDefault(); performSearch(); }}>
        <h6 className="title mb-20 text-uppercase fw-normal">search</h6>
        <div className="form-group position-relative">
          <input
            type="text"
            className="form-control rounded-pill"
            value={query}
            onChange={handleInputChange}
          />
          <button type="submit" className="search-btn border-0 bg-transparent pb-0">
            <i style={{ fontSize: '25px' }} className="fas fa-search">
              <RiSearchLine />
            </i>
          </button>
        </div>
      </form>

      <div className="side-categories mb-5">
        <h6 className="title mb-20 text-uppercase fw-normal">categories</h6>
        {categories?.map((category) => (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => handleCategoryClick(category?.name)}
            key={category?.id}
            className="cat-item"
          >
            <span>{category?.name}</span>
            <span>{category?.posts_count}</span>
          </span>
        ))}
      </div>

      {Loading ? (
        <h4 className="text-center m-auto mt-5">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </h4>
      ) : (
        <>
          <div className="side-recent-post mb-50">
            <h6 className="title mb-20 text-uppercase fw-normal">recent post</h6>
            {posts?.recent_posts?.map((post) => (
              <Link to={`/blog/${post?.slug}`} className="post-card mb-2" key={post?.id}>
                <div className="img me-3">
                  <LazyImage
                    src={`${post?.image}`} // Replace with your actual image URL
                    alt=""
                    placeholder={post?.title}
                  />
                </div>
                <div className="inf">
                  <h6 className="mb-2">
                    <TextTruncate line={1} element="span" truncateText="…" text={post?.title} />
                  </h6>
                  <p className="fs-13px">
                    <TextTruncate line={2} element="span" truncateText="…" text={post?.short_body} />
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogSliderBar;

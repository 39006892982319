import React from 'react'
import Testimony from '../assets/images/testimonial_1.png'

function Testimonial() {
    return (

        <section class="section section-padding">

            <div class="container">

                <div class="section-heading heading-left">
                    <span class="subtitle">Testimonial</span>
                    <h2 class="title">From getting started</h2>
                    <p>Discover what our satisfied customers are saying about us! Read their testimonials and experience our commitment to excellence.</p>
                </div>

                <div class="row">

                    <div class="col-md-6">
                        <div class="testimonial-grid">
                            <p style={{ fontSize: '18px', lineHeight: '30px' }}>“ Social Media Coverage of our conferences and event has never been handled professionally the way inertia Media handled it ”</p>
                            <div class="author-info">
                                {/* <div class="thumb">
                                    <img src={Testimony} alt="Google Review" />
                                </div> */}
                                <div class="content">
                                    <span class="name">Wale Afonja</span>
                                    <span class="designation">Director programs, ALDC, Covenant University</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="testimonial-grid">
                            <p style={{ fontSize: '18px', lineHeight: '30px' }}>“ Inertia Media did a great job on our online marketing endeavors ”</p>
                            <div class="author-info">
                                {/* <div class="thumb">
                                    <img src={Testimony} alt="Google Review" />
                                </div> */}
                                <div class="content">
                                    <span class="name">Dr Mario Matthew</span>
                                    <span class="designation">Registrar Myles Leadership University</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class="col-lg-4">
                        <div class="testimonial-grid">
                            <p style={{ fontSize: '18px', lineHeight: '30px' }}>“ Donec metus lorem, vulputate
                                at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. ”</p>
                            <div class="author-info">
                                <div class="thumb">
                                    <img src={Testimony} alt="Google Review" />
                                </div>
                                <div class="content">
                                    <span class="name">Floyd Miles</span>
                                    <span class="designation">Executive Chairman</span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </section>

    )
}

export default Testimonial
import React from 'react'
import Banner from "./../components/Banner";
import Clients from "./../components/Clients";
import Footer from "./../components/Footer";
import Header from "./../components/Header";
import Testimonial from "./../components/Testimonial";
import Line2 from './../assets/images/line_2.png'
import Bubble2 from './../assets/images/bubble_4.png'
import Bubble3 from './../assets/images/bubble_3.png'
import Icon from './../assets/images/icon_1.png'
import Projects from "./../components/Projects";
import Call2Action from "./../components/Call2Action";

export const Home = () => {
    return (

        <div className="">

            {/* HEADER */}

            <Header />

            {/* BANNER */}

            <Banner />

            {/* About */}

            <section class="section section-padding-2 bg-color-dark">
                <div class="container">
                    <div class="section-heading heading-light-left">
                        <span class="subtitle">What We Can Do For You</span>
                        <p class="fw-light">We are experts in developing solid online web presences, crafting content & creatives that will attract and engage your audience online, as well as training & consultancy on the best digital solutions for your business.</p>
                    </div>

                    <div class="row">

                        <div class="col-xl-4 col-md-6" >
                            <div class="services-grid active">
                                <div class="thumbnail">
                                    <img src={Icon} alt="icon" />
                                </div>
                                <div class="content">
                                    <h5 class="title text-white"> <span>Social Media Marketing</span></h5>
                                    <p className='fs-sm'>We help Clients leverage social media for their business growth by developing, implementing and managing social media marketing campaigns that create awareness, engage with prospects and turn followers into customers.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6" >
                            <div class="services-grid">
                                <div class="thumbnail">
                                    <img src={Icon} alt="icon" />
                                </div>
                                <div class="content">
                                    <h5 class="title text-white"> <span>Mobile Marketing</span></h5>
                                    <p className='fs-sm text-white'>Today’s consumers are mobile. They connect, engage and entertain themselves through their mobile devices. Let’s help you reach your consumers through mobile marketing using various mobile platforms like: WhatsApp,
                                        Targeted SMS,
                                        Live Chat,
                                        Mobile Apps, e.t.c
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6" >
                            <div class="services-grid">
                                <div class="thumbnail">
                                    <img src={Icon} alt="icon" />
                                </div>
                                <div class="content">
                                    <h5 class="title text-white"> <span>Brand Development
                                    </span> </h5>
                                    <p className='fs-sm'>All products need to be developed into brands that gives them a distinct identity in the minds of the customers. At Inertia Media, we help turn products, companies and people into distinguishable brands with the following services that we offer:
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <ul class="list-unstyled shape-group-10">
                    <li class="shape shape-1"><img src={Line2} alt="Circle" /></li>
                    <li class="shape shape-2"><img src={Bubble3} alt="Circle" /></li>
                    <li class="shape shape-3"><img src={Bubble2} alt="Circle" /></li>
                </ul>
            </section>


            <Testimonial />

            {/* CLIENTS */}

            <Clients />

            {/* <Projects /> */}

            <Call2Action />

            <Footer />

        </div>

    )
}
import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Service from '../../assets/images/services/4.jpg'

const GrowthMarketing = () => {

  const [ show, setShow ] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Growth Marketing Services' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">
            
            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Growth Marketing Services</span>

              <p className='mb-4'>Our Growth Marketing Service Package utilizes multiple digital marketing tactics and platforms to create awareness for your brand, engage with prospects and customers, generate leads and nurture them to become customers.</p>


              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Book A Free Consultation</Link>

            </div>
          </div>

        </div>

      </section>

      <Footer />

      <Modal show={show} setShow={setShow} title='Growth Marketing Services'/>

    </div>
  )
}

export default GrowthMarketing
import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import Service from '../../assets/images/services/1.jpg'

const SocialMedia = () => {

  const [show, setShow] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Social Media Marketing' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work">

            <div class="thumbnail paralax-image">
              <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Social Media Marketing</span>

              <p className='mb-4'> Your customers and prospects are on Social media. Your brand should be too.</p>

              <p className='mb-4'>We help Clients leverage social media for their business growth by developing, implementing and managing social media marketing campaigns that create awareness, engage with prospects and turn followers into customers.</p>

              <p className='mb-4'>Our Social Media Services Include:
                Social Media AdvertisingSocial Media ManagementSocial Media Content DevelopmentCustomer/Community Relations
                Looking to leverage Social Media for business growth?
                Talk To A Social Media Strategist</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Get Started</Link>

            </div>
            
          </div>

        </div>

      </section>

      <Footer />

      <Modal show={show} setShow={setShow} title='Social Media Marketing' />

    </div>
  )
}

export default SocialMedia
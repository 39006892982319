import React from 'react'

const Modal = (props) => {

    const { show, setShow, title } = props;

    return (
        <>

            <div className={`modal fade ${show ? 'show' : ''}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ paddingTop: '50px', display: show ? 'block' : 'none' }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel"> {title} </h1>
                            <button onClick={() => setShow(false)} type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">  </button>
                        </div>

                        <div class="modal-body">

                            <div class="px-2">
                                <form class="axil-contact-form">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" name="contact-name" placeholder="John Smith" />
                                    </div>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" class="form-control" name="contact-email" placeholder="example@mail.com" />
                                    </div>
                                    <div class="form-group mb--40">
                                        <label>How can we help you?</label>
                                        <textarea name="contact-message" id="message" class="form-control textarea" cols="30" rows="6"></textarea>
                                    </div>

                                    <div class="form-group text-end">
                                        <button type="submit" class="axil-btn btn-fill-primary btn-sm w-100 btn-primary" name="submit-btn">SEND</button>
                                    </div>

                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {show && (<div className={`modal-backdrop fade ${show && 'show'} `}></div>)}

        </>
    )
}

export default Modal
import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Service from '../../assets/images/services/7.jpg'
import Service2 from '../../assets/images/services/11.jpg'

const Mobile = () => {

  const [ show, setShow ] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Mobile & Web App Development' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">
            
            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Mobile & Web App Development</span>

              <p className='fw-bold text-dark mb-2'>  <i>Mobile Is The Future Of Tech</i></p>
              <p className='fw-bold text-dark mb-2'>  <i>We develop iOS and Android Mobile Apps with fantastic User Interfaces and User Experiences</i></p>

              <p className='mb-4'>Yup! That's exactly what it is. A mobile app is like buying a piece of land (storage) on the smartphones of your clients or prospects, where you deploy an outlet of your business/brand, which they can carry around with them wherever they go, and conveniently interact with your business, whenever they choose to at Inertia media, we develop and design mobile apps with a smooth Flow of user experience,user interface and mobile security, that gives your prospect and clients solid confidence in your brand.</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Get Started</Link>

            </div>
          </div>

          <div class="process-work content-reverse" data-sal="slide-left" data-sal-duration="1000" data-sal-delay="100">
            <div class="thumbnail paralax-image">
               <img src={Service2} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Step Two</span>

              <p className='mb-4'>We also develop Web Apps to achieve any special functions or logic your business or brand requires...</p>

              <ul class="mb-3 text-dark">
                <li className='mb-2'>Web Apps for business Inventories and transaction Logs </li>
                <li className='mb-2'>Web Apps for financial institutions or Investment companies</li>
                <li className='mb-2'> Web Apps for staff or corporate collaborations and work flow  </li>
                <li className='mb-2'> Web Apps for Admin Access and monitoring of mobile App users </li>
                <li className='mb-2'> Social Media /Brand campaign web Apps  </li>
                <li className='mb-2'> Costumer relationship management (CRM)web Apps and so much more...</li>
              </ul>

              <p className='mb-4'>Let's Build your Web App Today</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Get Started</Link>

            </div>

          </div>

        </div>

      </section>

      <Footer />

      <Modal show={show} setShow={setShow}  title='Mobile & Web App Development' />

    </div>
  )
}
export default Mobile
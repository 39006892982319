import React, { useEffect, useState } from 'react'
import ScrollToTop from './components/ScrollTop'
import axios from './app/api/axios'

// PAGES

import { Home } from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import SocialMedia from './pages/services/SocialMedia';
import MobileMarketing from './pages/services/MobileMarketing';
import WebsiteDevelopment from './pages/services/WebsiteDevelopment';
import SearchEngine from './pages/services/SearchEngine';
import GrowthMarketing from './pages/services/GrowthMarketing';
import Consultancy from './pages/services/Consultancy';
import GOOGLE from './pages/services/GOOGLE';
import Mobile from './pages/services/Mobile';
import BrandCreative from './pages/services/BrandCreative';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import { Route, Routes, useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast';

const App = () => {

  const [posts, setPost] = useState([])
  const [isLoading, setLoading] = useState(false)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category') || '';
  const search = queryParams.get('search') || '';

  const fetchPost = async (pageNumber = 1) => {
    setLoading(true)
    const response = await axios.get(`/posts`, {
      params: {
        category: category,
        search: search,
        page: pageNumber
      },
    })
    setLoading(false)
    setPost(response?.data)
  }

  useEffect(() => {
    fetchPost()
  }, [search, category, location])

  return (

    <>

      <ScrollToTop />

      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/blog' element={<Blog posts={posts} Loading={isLoading} fetchPost={fetchPost} />} />
        <Route path='/about' element={<About />} />
        <Route path='/blog/:slug' element={<BlogDetails Loading={isLoading} posts={posts} />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/portfolio' element={<Home />} />
        <Route path='/social-media-marketing' element={<SocialMedia />} />
        <Route path='/mobile-marketing' element={<MobileMarketing />} />
        <Route path='/website-development' element={<WebsiteDevelopment />} />
        <Route path='/search-engine-optimisation' element={<SearchEngine />} />
        <Route path='/growth-marketing-services' element={<GrowthMarketing />} />
        <Route path='/consultancy' element={<Consultancy />} />
        <Route path='/search-engine-optimisation' element={<SearchEngine />} />
        <Route path='/google-online-display-ad' element={<GOOGLE />} />
        <Route path='/mobile-app-development' element={<Mobile />} />
        <Route path='/brand-creative' element={<BrandCreative />} />

      </Routes>

      <Toaster
        position="top-center"
        reverseOrder={false}
      />

    </>

  )
}

export default App
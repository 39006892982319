import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const LazyImage = ({ src, className, alt, placeholder }) => {
    const [imageSrc, setImageSrc] = useState(placeholder);
    const imageRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Load the actual image
              setImageSrc(src);
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 } // Adjust the threshold as per your requirement
      );
  
      if (imageRef.current) {
        observer.observe(imageRef.current);
      }
  
      return () => {
        if (imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    }, [src]);
  
    return <img ref={imageRef} src={`https://app.inertiamedia-agency.com${imageSrc}`} className={className} alt={alt} />;
  };
  
  export default LazyImage
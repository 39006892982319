import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Footer from "./../components/Footer";
import Header from "./../components/Header";
import { RiFacebookFill, RiInstagramFill, RiWhatsappFill } from 'react-icons/ri';
import { FaFacebook } from 'react-icons/fa';

function About() {
    return (

        <div className="">

            <Header />

            <BreadCrumb title='Contact Us' />

            <section class="section section-padding">
                <div class="container">
                    <div class="row">

                        <div class="col-xl-5 col-lg-6">
                            <div class="contact-form-box shadow-box mb--30">

                                <h3 class="title">Drop a message for Us</h3>

                                <form>

                                    <div class="form-group">
                                        <label>First Name</label>
                                        <input type="text" class="form-control" name="contact-name" />
                                    </div>

                                    <div class="form-group">
                                        <label>last Name</label>
                                        <input type="text" class="form-control" name="contact-name" />
                                    </div>

                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" class="form-control" name="contact-email" />
                                    </div>

                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="tel" class="form-control" name="contact-email" />
                                    </div>

                                    <div class="form-group">
                                        <label>Company Name</label>
                                        <input type="text" class="form-control" name="contact-email" />
                                    </div>

                                    <div class="form-group mb--40">
                                        <label>Message</label>
                                        <textarea name="contact-message" id="contact-message" class="form-control textarea" cols="30" rows="4"></textarea>
                                    </div>

                                    <div class="form-group">
                                        <button type="submit" class="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Submit</button>
                                    </div>

                                </form>
                            </div>
                        </div>

                        <div class="col-xl-5 col-lg-6 offset-xl-1">

                            <div class="contact-info mb--30 mt_md--0 mt--30">

                                <h5 class="title">Contact Details</h5>

                                <div className="mb-4">
                                    <h6 className='title text-uppercase'>Nigeria</h6>
                                    <p className='mb-2'>13th floor bookshop house, cms, Lagos Island, Nigeria.</p>
                                    <h6 class="phone-number"><a href="tel:+2348183826655">+234 818 3826 655</a></h6>
                                </div>

                                <div className="mb-4">
                                    <h6 className='title text-uppercase'>USA</h6>
                                    <p className='mb-2'>16192 Coastal Highway, Lewes, DE 19958, USA</p>
                                    <h6 class="phone-number"><a href="tel:+13322221801 ">+13322221801 </a></h6>
                                </div>

                                <div className="mb-4">
                                    <h6 className='title text-uppercase'>Rwanda</h6>
                                    <p className='mb-2'>Suite 3 Erica Building, Izuba City Apartments, Batsinda, Kigali, Rwanda.</p>
                                    <h6 class="phone-number"><a href="tel:+250790137970">+250 790 137 970</a></h6>
                                </div>

                                <div className="mb-4">
                                    <h6 className='title text-uppercase'>Benin Republic</h6>
                                    <p className='mb-2'>Lot 680 Dandji, Akpakpa, Cotonou Benin Republic.</p>
                                    <h6 class="phone-number"><a href="tel:+22968587734">+22968587734</a></h6>
                                </div>

                                <div className="mb-4">
                                    <h6 className='title text-uppercase'>Kenya</h6>
                                    <p className='mb-2'>2 Ring road, kilimani, Nairobi, Kenya.</p>
                                    <h6 class="phone-number"><a href="tel:+254740573937">+254740573937</a></h6>
                                </div>


                            </div>

                            <div class="contact-info">

                                <h4 class="title">Social Media and Email</h4>
                                <h6 class="phone-number"><a href="mailto:hello@inertiamedia-agency.com"> hello@inertiamedia-agency.com</a></h6>

                                <div class="banner-social mt-3">
                                    <div class="border-line"></div>
                                    <ul class="list-unstyled social-icon">

                                        <li><a className='text-white pb-3' target='_blank' rel="noreferrer" href="https://web.facebook.com/profile.php?id=100063033681192&mibextid=ZbWKwL&_rdc=1&_rdr"><i class="fab fa-facebook-f"> <FaFacebook /> </i> Facebook</a></li>

                                        <li><a className='text-white pb-3' target='_blank' rel="noreferrer" href="https://wa.link/r0jszu"><i class="fab fa-whatsapp"> <RiWhatsappFill /> </i> Whatsapp</a></li>

                                        <li><a className='text-white pb-3' target='_blank' rel="noreferrer" href="https://instagram.com/officialinertiamedia?igshid=YmMyMTA2M2Y="><i class="fab fa-linkedin-in"> <RiInstagramFill /> </i> Instagram</a></li>

                                    </ul>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>

            </section>

            <Footer />

        </div>

    )
}

export default About
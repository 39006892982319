import React from 'react'
import Brand from '../assets/images/clients/1.jpeg'
import Brand2 from '../assets/images/clients/2.jpeg'
import Brand3 from '../assets/images/clients/3.jpeg'
import Brand4 from '../assets/images/clients/4.jpeg'
import Brand5 from '../assets/images/clients/5.jpeg'
import Brand6 from '../assets/images/clients/6.jpeg'
import Brand7 from '../assets/images/clients/7.jpeg'
import Brand8 from '../assets/images/clients/8.jpeg'
import Brand9 from '../assets/images/clients/9.jpeg'
import Brand10 from '../assets/images/clients/10.jpeg'

function Clients() {
    return (

        <section class="section section-padding bg-color-dark">

            <div class="container">

                <div class="section-heading heading-light-left">
                    <span class="subtitle">Top Clients</span>
                    <h2 class="title">We’ve built solutions for...</h2>
                </div>

                <div class="d-flex align-items-center justify-content-between flex-wrap m-auto text-center">

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand2} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand3} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand4} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand5} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand6} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand7} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand8} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand9} alt="Brand" />
                        </div>
                    </div>

                    <div class="" data-sal="slide-up" data-sal-duration="500">
                        <div class="brand-grid active">
                            <img src={Brand10} alt="Brand" />
                        </div>
                    </div>
                 
                </div>

            </div>

        </section>

    )
}

export default Clients
import React from 'react'
import BG from '../assets/images/bg.png'
import Bubble1 from '../assets/images/bubble_1.png'
import Bubble2 from '../assets/images/bubble_2.png'
import Line from '../assets/images/line_1.png'
import { RiFacebookFill, RiInstagramFill, RiWhatsappFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import BG2 from '../assets/images/services/2.jpg'


function Banner() {
    return (

        <>

            <div class="banner breadcrum-area breadcrumb-banner single-breadcrumb">

                <div class="container">

                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="section-heading heading-left mt-3">
                                <h1 class="title h2">We are a new media and digital marketing agency</h1>

                                <p className='my-0 py-0'>We help you achieve your desired results using digital tools and skills.</p>
                                <p className='my-0 pt-2'> We’ll Always Make Your Business Stand-Out From The Crowd!. </p>

                                <Link to="/about" class="axil-btn btn-fill-primary btn-large mt-5">About Us</Link>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="banner-thumbnail" data-sal="slide-up" data-sal-duration="1000" data-sal-delay="400">
                                <img class="paralax-image" src={BG2} alt="Illustration" />
                            </div>
                        </div>
                    </div>

                    {/* BANNER SOCIAL MEDIA */}

                    <div class="banner-social mt-5">
                        <div class="border-line"></div>
                        <ul class="list-unstyled social-icon">

                            <li><a target='_blank' rel="noreferrer" href="https://web.facebook.com/profile.php?id=100063033681192&mibextid=ZbWKwL&_rdc=1&_rdr"><i class="fab fa-facebook-f"> <RiFacebookFill /> </i> Facebook</a></li>

                            <li><a target='_blank' rel="noreferrer" href="https://wa.link/r0jszu"><i class="fab fa-whatsapp"> <RiWhatsappFill /> </i> Whatsapp</a></li>

                            <li><a target='_blank' rel="noreferrer" href="https://instagram.com/officialinertiamedia?igshid=YmMyMTA2M2Y="><i class="fab fa-linkedin-in"> <RiInstagramFill /> </i> Instagram</a></li>

                        </ul>
                    </div>

                </div>

                <ul class="shape-group-8 list-unstyled">
                    <li class="shape shape-1" data-sal="slide-right" data-sal-duration="500" data-sal-delay="100">
                        <img src={Bubble1} alt="Bubble" />
                    </li>
                    <li class="shape shape-2" data-sal="slide-left" data-sal-duration="500" data-sal-delay="200">
                        <img src={Bubble2} alt="Bubble" />
                    </li>
                    <li class="shape shape-3" data-sal="slide-up" data-sal-duration="500" data-sal-delay="300">
                        <img src={Line} alt="Line" />
                    </li>
                </ul>
            </div>

        </>

    )
}

export default Banner
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import BlogSliderBar from './../components/blog/BlogSliderBar'
import BlogComment from '../components/blog/BlogComment'
import { FaClock, FaShare } from 'react-icons/fa'
import axios from '../app/api/axios'
import moment from 'moment'
// import TextToSpeechButton from '../components/TextToSpeech'
import LazyImage from '../components/LazyImage'

const BlogDetails = ({ posts, Loading }) => {

    const { slug } = useParams()
    const [post, setPost] = useState({})
    const [loading, setLoading] = useState(false)

    const getPost = async () => {
        setLoading(true)
        const response = await axios.get(`/post/${slug}`)
        setPost(response?.data);
        setLoading(false)
    }

    useEffect(() => {
        getPost()
    }, [slug])

    const handleShare = (post) => {

        const shareUrl = window.location.href;; // Replace with your actual blog post URL

        if (navigator.share) {
            // Check if the Web Share API is supported
            navigator.share({
                title: post?.title,
                text: post?.short_body,
                url: shareUrl,
            })
                .then(() => console.log('Shared successfully'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            // Fallback for browsers that don't support the Web Share API
            alert('Sharing is not supported on this browser. Please use the social media icons below to share the post manually.');
        }
    };

    return (
        <>

            <Header />

            <div className="blog-page style-5">

                <section class="all-news section-padding pt-50 blog bg-transparent style-3">

                    <div class="container">

                        {loading ? (

                            <h4 className="text-center m-auto mt-5">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" style={{ width: '5rem', height: '5rem' }} role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </h4>

                        ) : (

                            <>

                                <div class="blog-details-slider mb-5">

                                    <div class="section-head text-center mb-5 style-5">
                                        <h3 class="mb-2 text-dark fw-bolder"> {post?.title} </h3>
                                        <small class="d-block date text">

                                            <a href="#" class="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold"> {post?.category?.name} </a>

                                            <i class="bi bi-clock me-1 text-dark "> <FaClock /> </i>
                                            <span class="op-8 border-end brd-gray pe-3 me-3">Posted {moment(post?.created_at).startOf('hour').fromNow()}</span>

                                            <span className='border-end brd-gray pe-3 me-3'> {post?.views} View(s) </span>

                                            <span className=''> {post?.reading_time} min Read </span>

                                        </small>

                                        {/* <TextToSpeechButton text={post?.body} /> */}

                                    </div>

                                    <div class="content-card">

                                        <div class="img">
                                            <LazyImage
                                                src={`${post?.image}`} // Replace with your actual image URL
                                                alt=""
                                                placeholder={post?.title}
                                            />
                                        </div>

                                        {/* <div class="info">
        <div class="row">
            <div class="col-lg-6">
                <div class="cont">
                    <small class="date small mb-20"> <span class="text-uppercase border-end brd-gray pe-3 me-3"> News </span> <i class="far fa-clock me-1"></i> Posted  {moment(post?.created_at).startOf('hour').fromNow()} </small>
                    <h2 class="title">
                        Solutions For Big Data Issue, Expert Perspective
                    </h2>
                    <p class="fs-12px mt-10 text-light text-info">If there’s one way that wireless technology has changed the way we work, it’s that will everyone is now connected [...]</p>
                </div>
            </div>
        </div>
    </div> */}

                                    </div>

                                </div>

                                <div class="row gx-4 gx-lg-5">

                                <div class="col-lg-4 col-mobile">
                                        <BlogSliderBar Loading={Loading} posts={posts} />
                                    </div>

                                    <div class="col-lg-8">
                                        <div class="d-flex small align-items-center justify-content-between mb-5 fs-12px">
                                            <div class="l_side d-flex align-items-center">
                                                <span href="#" class="me-3 me-lg-5">
                                                    <span class="icon-20 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-main p-1 me-2 text-white">
                                                        a
                                                    </span>
                                                    <span class="">
                                                        By {post?.user?.name}
                                                    </span>
                                                </span>

                                                <span href="#" class="me-3 me-lg-5">
                                                    <i class="bi bi-chat-left-text me-1"></i>
                                                    <span> {post?.comments_count} Comment(s) </span>
                                                </span>
                                            </div>
                                            <div style={{ cursor: 'pointer' }} class="r-side mt-1">
                                                <span className='text-primary' onClick={() => handleShare(post)}>
                                                    <i class="bi bi-info-circle me-1"> <FaShare /> </i>
                                                    <Link>Share</Link>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="blog-content-info mt-5">
                                            <h5 class="fw-bold color-000 lh-4 mb-3"> {post?.title} </h5>

                                            <div dangerouslySetInnerHTML={{ __html: post?.body }} class="text mb-3 color-666"></div>

                                            {/* <BlogComment post={post} getPost={getPost} /> */}
                                        </div>
                                    </div>


                                </div>


                            </>

                        )}

                    </div>
                </section>

            </div>

            <Footer />

        </>
    )
}

export default BlogDetails
import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Footer from "./../components/Footer";
import Header from "./../components/Header";
import Seun from '../assets/images/seun.jpg'
import Mobile from '../assets/images/Mobile.png'
import Clients from '../components/Clients';
import Proceed from '../assets/images/bg.png'

function About() {
    return (

        <div className="">

            <Header />

            <BreadCrumb title='About Us' />

            <section class="section section-padding case-study-featured-area">
                <div class="container">
                    <div class="row">

                        <div class="col-xl-7 col-md-6">
                            <div class="case-study-featured-thumb text-start">
                                <img className='img-fluid rounded-5 about_image' src={Mobile} alt="travel" />
                            </div>
                        </div>

                        <div class="col-xl-5 col-md-6 align-self-center">
                            <div class="case-study-featured">
                                <div class="section-heading heading-left">
                                    <span class="subtitle">Welcome to Inertia Media</span>
                            
                                    <p>We are a digital marketing agency focused on helping individuals and businesses achieve their desired results, using digital tools and skills.</p>

                                    <p>We work with specialized product brands and professional services firms who are looking to grow and need to attract new customers and generate leads to fuel that growth.</p>

                                    <p>Powered by a multi-disciplinary team with several years work experience, Inertia Media leverages on their unique skill sets and broad experience garnered from stints in Software development, advertising, public relations, publishing, and media as well as marketing and corporate communications to meet the needs of discerning clients.</p>

                                    <p>From strategy and planning right through to execution and reporting, we’ve got your back and we are consistently working to deliver extensive return on your investment.</p>

                                    <p>Looking to partner with us to grow your brands?</p>

                                </div>
                                <div class="case-study-counterup">
                                    <div class="single-counterup">
                                        <h2 class="count-number">
                                            <span class="number count" data-count="5">5</span>
                                            <span class="symbol">+</span>
                                        </h2>
                                        <span class="counter-title">Years on the market</span>
                                    </div>
                                    <div class="single-counterup">
                                        <h2 class="count-number">
                                            <span class="number count" data-count="1500">1500</span>
                                            <span class="symbol">+</span>
                                        </h2>
                                        <span class="counter-title">Projects delivered so far</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/* <section class="section-padding">

                <div class="container">
                    <div class="row">

                        <div class="col-lg-6">

                            <div class="why-choose-us">

                                <div class="section-heading heading-left">
                                    <span class="subtitle">About Us</span>
                                    <h3 class="title">Why branding matters?</h3>
                                    <p className=''>Ut condimentum enim nec diam convallis mollis. Sed felis quam, semper dapibus purus sed, rhoncus ullamcorper lacus.</p>
                                </div>

                                <div class="accordion" id="choose-accordion">

                                    <div class="accordion-item">

                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <i class="far fa-compress"> <FaCompress /> </i> Strategy
                                            </button>
                                        </h2>

                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#choose-accordion">
                                            <div class="accordion-body">
                                                Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                            </div>
                                        </div>

                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <i class="far fa-code"><FaCompress /></i>Design
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#choose-accordion">
                                            <div class="accordion-body">
                                                Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <i class="fal fa-globe"><FaCompress /></i>Development
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#choose-accordion">
                                            <div class="accordion-body">
                                                Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="col-xl-5 col-lg-6 offset-xl-1 h-100 align-self-center">
                            <div class="thumbnail paralax-image">
                                <img className='rounded' src={Proceed} alt="Thumbnail" />
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            <Clients />

            <Footer />

        </div>

    )
}

export default About
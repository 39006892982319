import React from 'react'
import BlogImage from '../../assets/images/blog.png'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TextTruncate from 'react-text-truncate';
import LazyImage from '../LazyImage';

const BlogCard = ({ post }) => {

    return (
        <Link to={`/blog/${post?.slug}`} class="card border-0 bg-transparent rounded-0 border-bottom brd-gray pb-30 mt-4">
            <div class="row">

                <div class="col-lg-5">
                    <div class="img img-cover">
                        <LazyImage
                            src={`${post?.image}`} // Replace with your actual image 
                            className="radius-7"
                            placeholder={post?.title}
                        />
                    </div>
                </div>

                <div class="col-lg-7 align-self-center mt-4">
                    <div class="card-body p-0">
                        <small class="d-block date text">

                            <Link to={`/blog/${post?.slug}`} class="text-uppercase border-end brd-gray pe-2 me-2 color-blue5 fw-bold"> {post?.category?.name} </Link>

                            <i class="bi bi-clock me-1"></i>

                            <span to='' class="op-8 brd-gray text-dark pe-2 border-end me-2"> {moment(post?.created_at).format('llll')} </span>

                            <span to='' class="op-8 brd-gray text-dark pe-2 border-end me-2"> {post?.views} View(s) </span>

                            <span to='' class="op-8 text-dark"> {post?.reading_time} Min Read </span>

                        </small>
                        <Link to={`/blog/${post?.slug}`} class="text-dark card-title mb-2">
                            <TextTruncate
                                line={1}
                                element="span"
                                truncateText="…"
                                text={post?.title}
                            />  </Link>

                        <p class="fs-13px pb-0 text-dark mb-0">  <TextTruncate
                            line={3}
                            element="span"
                            truncateText="…"
                            text={post?.short_body}
                        /> </p>

                    </div>
                </div>

            </div>
        </Link>
    )
}

export default BlogCard
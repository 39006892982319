import React from 'react'

function Footer() {
    return (
        <footer class="footer-area">

            <div class="container">
                
                <div class="footer-bottom" data-sal="slide-up" data-sal-duration="500" data-sal-delay="100">
                    <div class="row">

                        <div class="col-md-6 text-center m-auto">
                            <div class="footer-copyright">
                                <span class="copyright-text">© 2018 - { new Date().getFullYear() } . All rights reserved is Inertia Media.</span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </footer>
    )
}

export default Footer
import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import { Link } from 'react-router-dom';
import Website from './../../assets/images/projects/Website.png'
import web2 from './../../assets/images/projects/web2.png'
import Modal from '../../components/Modal';
import Service from '../../assets/images/services/process.png'

const WebsiteDevelopment = () => {

  const [show, setShow] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Website Development' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">

            <div class="thumbnail paralax-image">
              <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Website Development</span>

              <p className='mb-4'> Your website is your online office address and your 24-hour sales tool. We design functional, user-friendly and marketing oriented websites that serve as a sales and marketing tool for your business.</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Get Started</Link>

            </div>
          </div>

          <div class="process-work content-reverse" data-sal="slide-left" data-sal-duration="1000" data-sal-delay="100">
            <div class="thumbnail paralax-image">
              <img src={Service} alt="Thumbnail" />
            </div>
            <div class="content">

              <div className="subtitle">Keyword Optimised</div>

              <p className='mb-4'>Best SEO practices, images, meta- tags, back-links to external and internal pages
                Installed Analytics</p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Get Started</Link>

            </div>
          </div>

        </div>

      </section>

      <section class="section section-padding-equal bg-color-dark">
        <div class="container">

          <div class="section-heading heading-light-left">
            <h2 class="title">Selected projects</h2>
            <p className='fw-light'>Here are some websites we built for some of our clients
              All the websites we design and develop meet the following requirements:
              Mobile Responsiveness</p>
          </div>

          <div class="row row-45">

            <div class="col-md-4 col-sm-6">
              <div class="project-grid project-style-2">
                <div class="thumbnail">
                  <Link>
                    <img src={Website} alt="project" />
                  </Link>
                </div>
                <div class="content">
                  <span class="subtitle">ios, design</span>
                  <h3 class="title"><Link>Mark Design</Link></h3>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="project-grid project-style-2">
                <div class="thumbnail">
                  <Link>
                    <img src={web2} alt="project" />
                  </Link>
                </div>
                <div class="content">
                  <span class="subtitle">ios, design</span>
                  <h3 class="title"><Link>Mark Design</Link></h3>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="project-grid project-style-2">
                <div class="thumbnail">
                  <Link>
                    <img src={Website} alt="project" />
                  </Link>
                </div>
                <div class="content">
                  <span class="subtitle">ios, design</span>
                  <h3 class="title"><Link>Mark Design</Link></h3>
                </div>
              </div>
            </div>

          </div>

          {/* <div class="more-project-btn">
            <Link to="/portfolio" class="axil-btn btn-fill-white">Discover More Projects</Link>
          </div> */}

        </div>
      </section>

      <Footer />

      <Modal show={show} setShow={setShow} title='Website Development' />

    </div>
  )
}

export default WebsiteDevelopment
import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Service from '../../assets/images/services/process.png'

const GOOGLE = () => {

  const [ show, setShow ] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Google Online Dispaly AD Campaigns' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">

            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">GOOGLE ONLINE DISPLAY AD CAMPAIGNS</span> <br />

              <p className='fw-bold text-dark mb-2'>  <i>Great Ads Are The Lifeblood Of Great Businesses</i></p>
              <p className='fw-bold text-dark mb-2'>  <i>We Google Display Ads That Drive Conversations!</i></p>


              <p className='my-4'> <span className="text-dark fw-bold mb-2"> Google Display Ads</span> <br /> With the large number of website connected to Google Display Ads Network, your business has a massive potential to reach a large chunk of your target audience who are active visitors of these websites. Plus with YouTube currently being the largest watched online video directory, we can serve ads of your business to the ever glued viewers on YouTube, engage them, & drive conversations and sales for your business. Also, with Google Visitor Tag triggered, "Retargeting Ads", we can keep serving your display ads to your target audiences till they close the sale!</p>


              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Get Started</Link>

            </div>
          </div>

        </div>


      </section>

      <Footer />

      <Modal show={show} setShow={setShow} title='Google Online Dispaly AD Campaigns'/>

    </div>
  )
}

export default GOOGLE
import React, { useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb';
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';
import Service from '../../assets/images/services/5.jpg'

const Consultancy = () => {

  const [ show, setShow ] = useState(false)

  return (
    <div className="">

      <Header />

      <BreadCrumb title='Consultancy & Training' />

      <section class="section section-padding bg-color-light pb--70">
        <div class="container">

          <div class="process-work" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">

            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <span class="subtitle">Consultancy & Training</span>

              <p className='fw-bold text-dark mb-2'>  <i>We Consult On Digital Solutions For Corporate Brands</i></p>

              <p className='mb-4 fs-sm'> Digital Solutions & Branding Are Evolving The Marketplace Everyday.
                Don't be left out! At Inertia Media we consult for and train the staffs teams of  corporate brands on the latest digital solutions, digital marketing & branding trends, to keep them in sync with steps and processes that builds their brand in the ever-evolving marketplace.
                Let's Train Your Team Today</p>

                <p className='fs-sm'>We Also Run Certified Digital Solutions & Branding Trainings For Individuals</p>

            </div>
          </div>

          <div class="process-work content-reverse" data-sal="slide-left" data-sal-duration="1000" data-sal-delay="100">
            <div class="thumbnail paralax-image">
               <img src={Service} alt="Thumbnail" />
            </div>

            <div class="content">

              <p className='mb-4'>At Inertia Media, we also run professional Digital Solutions (Website Design, Graphics & Creative Design, Video Production, e.t.c), Digital Marketing, & Branding training programs for individuals, that comes with a full certificate of completion, after you've concluded any of the training programs</p>

              <ul class="mb-3 text-dark">
                <li className='mb-2'>Professional Facebook & IG Ads Campaign Course</li>
                <li className='mb-2'>Professional Google Display Ads Campaign Course</li>
                <li className='mb-2'>Professional Branding & Design Course</li>
                <li className='mb-2'>Professional Google Tools Utilization Course</li>
                <li className='mb-2'>Professional Windows Office Utilization Course</li>
                <li className='mb-2'>Professional Game Development Course </li>
                <li className="mb-2">Professional Copyrighting & StoryTelling Course</li>
                <li className="mb-2">Professional Online Funnels Generation Course</li>
                <li className="mb-2">Professional Cold prospecting Course</li>
              </ul>

              <p className="fs-sm mb-2">Join A Training Program Today</p>
              <p className="fs-sm mb-3"> <i>We Can Also Come To Facilitate At Your Event...</i> </p>

              <Link onClick={() => setShow(true)} class="axil-btn btn-borderd btn-sm">Talk To Us Now</Link>

            </div>

          </div>

        </div>

      </section>

      <Footer />
      
      <Modal show={show} setShow={setShow} title='Consultancy & Training'  />

    </div>
  )
}

export default Consultancy
import React from 'react'
import { Link } from 'react-router-dom'
import Bubble1 from '../assets/images/bubble_1.png'
import Bubble2 from '../assets/images/bubble_6.png'
import Line from '../assets/images/line_1.png'

function BreadCrumb({ title }) {
    return (

        <div class="breadcrum-area">
            <div class="container">
                <div class="breadcrumb mt-3">
                    <ul class="list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li class="active"> { title } </li>
                    </ul>
                    <h1 class="title h3 fw-bold text-dark"> { title } </h1>
                </div>
            </div>

            <ul class="shape-group-8 list-unstyled">

                <li class="shape shape-1 sal-animate">
                    <img src={Bubble1} alt="Bubble" />
                </li>

                <li class="shape shape-2 sal-animate">
                    <img src={Bubble2} alt="Bubble" />
                </li>

                <li class="shape shape-3 sal-animate">
                    <img src={Line} alt="Line" />
                </li>

            </ul>

        </div>

    )
}

export default BreadCrumb